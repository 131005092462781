<template>
  <v-card class="d-flex flex-column fill-height pa-4" v-if="retailer">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ retailer.retailer }}</v-toolbar-title>
      <v-spacer></v-spacer>
        <router-link to="/retailers"><v-icon>mdi-close</v-icon></router-link>
    </v-toolbar>
    <v-card-text class="fill-height d-flex flex-row">
      <v-row>
        <v-col class="col-3">
          <h3 v-if="retailer.addressLine1">{{retailer.addressLine1}}</h3>
          <h3 v-if="retailer.addressLine2">{{retailer.addressLine2}}</h3>
          <h3 v-if="retailer.addressLine3">{{retailer.addressLine3}}</h3>
          <h3 v-if="retailer.addressLine4">{{retailer.addressLine4}}</h3>
          <h3 v-if="retailer.city">{{retailer.city}}</h3>
          <h3 v-if="retailer.postalCode">{{retailer.postalCode}}</h3>
        </v-col>
        <v-col>
          <l-map :zoom="zoom" :center="center" v-if="center">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker :lat-lng="markerLatLng">
              <l-icon :icon-size="[40, 40]" :icon-url="redIcon" :icon-anchor="[20, 40]" />
            </l-marker>
          </l-map>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import RetailerApi from "@/api/retailers";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import moment from "moment";
import formatRetailerAddress from "@/utilities/formatRetailerAddress";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
  },
  data() {
    return {
      details: null,
      retailer: null,
      loading: true,
      loaded: true,
      selection: [],
      hour: moment().hours(),
      greenIcon:
        "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
      redIcon:
        "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
      mapOverlay: true,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: null,
      markerLatLng: null,
      imageUrl: process.env.VUE_APP_IMG_URL,
      iconSize: 40,
    };
  },
  mounted () {
    RetailerApi.loadRetailer(this.$router.currentRoute.params.id).then((results) => {
      this.retailer = results.data[0]
      this.details = formatRetailerAddress(this.retailer)
      if (this.retailer.latitude) {
        this.center = [this.retailer.latitude, this.retailer.longitude]
        this.markerLatLng = [this.retailer.latitude, this.retailer.longitude]
      }
      this.loading = false
    })
  },
  watch: {
    '$route.params': {
      handler: function(search) {
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onReady() {
      const bnds = this.$refs.features && this.$refs.features.mapObject.getBounds();
      this.$refs.map.fitBounds(bnds);
    },
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
    getAddress(item) {
      return formatRetailerAddress(item);
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
dl {
  width: 150px;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
dt {
  float: left;
  width: 25%;
  padding: 0;
  margin: 0;
  font-weight: 600;
}
dd {
  float: left;
  width: 75%;
  padding: 0;
  margin: 0;
}
</style>
