const addressFields = [
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'addressLine4',
  'city',
  'postalCode'
]

export default (user) => {
  const fields = []
  for (const field of addressFields) {
    if (user[field]) { fields.push(`${user[field]}`) }
  }
  return fields.join(', ')
}
