<template>
  <Retailer :key="$route.params.id" />
</template>


<script>
import Retailer from '@/components/RetailerDetail'
import RetailerApi from "@/api/retailers";
import { mapGetters } from 'vuex'
export default {
  metaInfo() {
    return {
      title: this.retailer?.retailer,
      titleTemplate: `%s | Doricmor`,
    };
  },
  components: {
    Retailer
  },
  data() {
    return {
      retailer: null,
      loading: true,
    };
  },
  computed: {
  },
  async created () {
    // RetailerApi.loadRetailer(this.$router.currentRoute.params.id).then((results) => {
    //   this.retailer = results.data[0]
    //   this.loading = false
    // })
  },
  methods: {
  },
};
</script>
<style scoped>
</style>
